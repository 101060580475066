button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  > * {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right > * {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.log-out-btn {
  @include btn(transparent, #337aed);
  width: 100%;
}

.brown-border-button {
  cursor: pointer;
  border: 2px solid $primary-color;
  background: transparent;
  min-width: 200px;
  min-height: 38px;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  position: relative;
  z-index: 1;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  //button animation
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $primary-color;
    z-index: -1;
    border-radius: 0;
    @include transition(transform 0.2s ease-in);

    @include scaleX(0);
    @include transform-origin(right);
  }

  &:hover,
  &:active,
  &:focus {
    border: 2px solid $primary-color;
    border-radius: 0;
    background: $primary-color;
    color: #000;

    &::after {
      @include scaleX(1);
      @include transform-origin(left);
    }
  }
}

.ant-btn-primary {
  border-radius: 0;
}
