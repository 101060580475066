main.ant-layout-content {
  section.ant-layout.ant-layout-has-sider.site-layout-background {
    height: 100%;
  }
}

// .sidebar-wrapper {
//   display: flex;
//   flex-direction: column;
//   align-content: space-between;
//   height: 100%;
// }

.log-out-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .brown-border-button {
    color: #fff;
    min-width: 170px;
  }
}

.ant-layout-sider {
  .logo {
    //border-bottom: 1px solid #dbe2ea;
    text-align: center;
    padding: 15px;
    padding-top: 50px;
    padding-bottom: 60px;

    img {
      max-height: 100% !important;
      max-width: 100% !important;
      width: 70%;
    }

    Button {
      margin-top: 15px;
    }
  }

  .ant-menu-submenu-title {
    padding-left: 34px !important;
    padding-right: 34px;
    text-align: left;

    .ant-menu-title-content {
      @include heading($large-font-size, $regular-line-height);
      color: $wht;
    }

    span {
      i {
        vertical-align: inherit;
        position: relative;
        top: -1px;
      }
    }

    .ant-menu-submenu-arrow {
      transform: translateY(2px);
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(0);
      }
    }
  }

  .ant-menu-item {
    margin-top: 0;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    a,
    a:visited {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-item-only-child {
  margin-bottom: 0;
  margin-top: 0;
}
.ant-layout-sider .ant-menu-item:last-child {
  margin-bottom: 0;
}

span.anticon {
  vertical-align: 0.15em;
}

// main.ant-layout-content {
//   padding: 0 0 0 15px !important;
// }

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    .logo {
      border: none;
      padding: 20px 0;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  main.ant-layout-content section.ant-layout.ant-layout-has-sider.site-layout-background {
    display: flex;
    flex-direction: column;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 100%;
    padding: 0px !important;
  }
}

// .ant-menu-submenu-open {
//   .ant-menu-submenu-title {
//     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 3%, rgba(0,0,0,0) 100%);
//     margin: 0;
//   }
//   .ant-menu-submenu-title:hover {
//     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 3%, rgba(0,0,0,0) 100%);
//   }
// }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:before {
  content: '';
  width: 5px;
  height: 30px;
  background: $wht;
  border-radius: 0 5px 5px 0;
  position: absolute;
  left: 0;
  top: 5px;
}

.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu {
  font-weight: 700;
}
